import axiosClient from "./axiosClient";

export const getBillingPlanList = () => {
  const url = `/billing`;
  return axiosClient.get(url);
};

export const assignBillingPlan = (param) => {
  const url = `/billing/`;
  return axiosClient.post(url, param);
};

export const getCustomerId = (param) => {
  const url = `/billing/customer/${param}`;
  return axiosClient.get(url);
};
