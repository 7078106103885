import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import {
  getBillingPlanList,
  assignBillingPlan,
  getCustomerId,
} from "../api-services/billing";

export const useBillingPlanList = () => {
  return useQuery("billingPlanList", getBillingPlanList, { enabled: true });
};

export const useAssignBillingPlan = () => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(assignBillingPlan, {
    onSuccess: () => {
      enqueueSnackbar("Your company has been assigned to billing", {
        variant: "success",
      });
      queryClient.invalidateQueries("companyInfo");
    },
  });
};
