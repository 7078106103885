import React, { useState, useMemo } from "react";
import "./billing-plan.scss";
import Typography from "@mui/material/Typography";
import {
  useBillingPlanList,
  useAssignBillingPlan,
} from "../../../hooks/billing.hooks";
import { useMyInfo } from "../../../hooks/user.hooks";

const BillingSetting = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const assignBillingPlan = useAssignBillingPlan();
  const { data: userInfo } = useMyInfo();

  const formatPrice = (cents, currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
    }).format(cents / 100);
  };

  const getIntervalLabel = (interval) => {
    const labels = {
      weekly: "/week",
      monthly: "/month",
      yearly: "/year",
    };
    return labels[interval] || "";
  };

  const usePlans = () => {
    const { data: result = [], isLoading, error } = useBillingPlanList();

    const transformedPlans = useMemo(
      () =>
        result.plans?.map((plan) => ({
          id: plan.lago_id,
          name: plan.name,
          code: plan.code,
          external_id: plan.lago_id,
          price: formatPrice(plan.amount_cents, plan.amount_currency),
          interval: getIntervalLabel(plan.interval),
          features: plan.description.split(",").map((description) => ({
            description,
          })),
          taxes: plan.taxes,
        })) || [],
      [result]
    );

    return {
      plans: { transformedPlans, name: result },
      isLoading,
      error,
    };
  };

  const { plans } = usePlans();

  const onSubmit = async (selectedId) => {
    setSelectedPlan(selectedId);
    const plan = plans.transformedPlans.find((p) => p.id === selectedId);
    if (!plan) return;

    assignBillingPlan.mutate({
      external_id: selectedId,
      external_customer_id: userInfo.companyId,
      plan_code: plan.code,
      name: plan.name,
    });
  };

  const PlanCard = ({ plan, isSelected, onSelect }) => (
    <div
      className={`plan-card ${isSelected ? "selected" : ""}`}
      onClick={onSelect}
    >
      <div className="plan-info">
        <h3>{plan.name}</h3>
        <div className="price">
          {plan.price}
          <span className="interval">{plan.interval}</span>
        </div>
      </div>

      <div className="plan-features">
        <ul className="features">
          {plan.features.map((feature) => (
            <li key={feature.id}>{feature.description}</li>
          ))}
        </ul>
      </div>

      <div className="plan-action">
        <button type="button" className="select-button" onClick={onSubmit}>
          {isSelected ? "Selected" : "Select Plan"}
        </button>
      </div>
    </div>
  );

  return (
    <div className="billing-plans">
      <Typography variant="h2" align="center" sx={{ mb: 4 }}>
        Choose Your Plan
      </Typography>
      <div className="plans-grid">
        {plans.transformedPlans.length > 0 ? (
          plans.transformedPlans.map((plan) => (
            <PlanCard
              key={plan.id}
              plan={plan}
              isSelected={selectedPlan === plan.id}
              onSelect={() => onSubmit(plan.id)}
            />
          ))
        ) : (
          <Typography align="center" sx={{ mb: 4 }}>
            Already subscibed to {plans.name}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default BillingSetting;
